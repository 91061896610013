


















































































































































import Vue from 'vue'
import Component, { mixins } from 'vue-class-component'
import VueRouter from 'vue-router'
import Vuex from 'vuex'
import _ from 'lodash'
import { ExternalCurriculumInfo, PagedResults, TableState } from '@/edshed-common/api/types'
import ModalMixin from '@/edshed-common/mixins/ModalMixin'
import { Api } from '@/edshed-common/api'
import ComponentHelperBase from '@/edshed-common/mixins/ComponentHelperBase'

@Component({})
export default class ExternalCurriculaView extends mixins(ComponentHelperBase, ModalMixin) {
  private tableState: TableState = {
    page: 1,
    perPage: 10,
    sort: 'id',
    dir: 'asc',
    term: ''
  }

  private results: PagedResults<ExternalCurriculumInfo> = {
    items: [],
    total: 0
  }

  private record: Partial<ExternalCurriculumInfo> | null = null

  public mounted () {
    this.loadData()

    this.$watch(() => this.$route, () => this.loadData())
    this.$watch(() => this.$store.state.user, () => this.loadData())
  }

  private async loadData () {
    this.results = await Api.getExternalCurricula(this.tableState, {})
  }

  private onPageChange (page: number) {
    this.tableState.page = page

    this.loadData()
  }

  private onSortChange (col: string) {
    const { sort, dir } = this.tableState

    if (sort !== col) {
      this.tableState.sort = col
      this.tableState.dir = 'asc'
    } else {
      this.tableState.dir = dir === 'asc' ? 'desc' : 'asc'
    }

    this.loadData()
  }

  private onSearchChange () {
    this.loadData()
  }

  private addRow () {
    this.record = {
      name: '',
      description: '',
      locale: 'en_GB',
      published: false
    }
  }

  private onEditRow (row: ExternalCurriculumInfo) {
    this.record = _.cloneDeep(row)
  }

  private async onDeleteRow (row: ExternalCurriculumInfo) {
    try {
      if (await this.deleteConfirm(row)) {
        await Api.deleteExternalCurriculum(row.id)

        this.loadData()
      }
    } catch (err: unknown) {
      if (err instanceof Error) {
        await this.alert({
          title: 'Error',
          message: err.message
        })
      }
    }
  }

  private onCloseEdit () {
    this.record = null
  }

  private async onSaveEdit () {
    const { id } = this.record!

    try {
      await Api.saveExternalCurriculum(this.record as ExternalCurriculumInfo)

      this.record = null
      this.loadData()
    } catch (err: unknown) {
      if (err instanceof Error) {
        await this.alert({
          title: 'Error',
          message: err.message
        })
      }
    }
  }
}
